<template>
<div class="CandidatureItem">
  <div class="gavc-card-candidacy gavc-text-small">
    <div class="gavc-card-candidacy__profil gavc-text-center">
      <p class="gavc-card-candidacy__profil--name gavc-strong">
        {{candidature.repreneur.user.first_name}} {{candidature.repreneur.user.last_name}}
      </p>
      <p class="gavc-card-candidacy__profil--age gavc-strong">
        {{ageFromBirthdate(candidature.repreneur.birthdate)}} ans
      </p>
      <p class="gavc-card-candidacy__profil--update gavc-strong">
        Il y a {{ancienneteFromDate(candidature.created_at)}}
      </p>
    </div>
    <div class="gavc-card-candidacy__infos text-left">
      <i v-if="candidature.description" class="gavc-icon gavc-icon-discussion"></i>
      <div v-if="candidature.description" class="gavc-card-candidacy__infos--text gavc-padding-small">
        <p v-if="candidature.description.length > 100" class="gavc-text-small gavc-margin-bottom">
          {{candidature.description.substring(0, 100)}} ...<br>
        </p>
        <p v-else class="gavc-text-small gavc-margin-bottom">
          {{candidature.description}}
        </p>
      </div>
    </div>
    <div class="gavc-card-candidacy__contact">
      <span v-if="isProfileCompleted" class="gavc-card-candidacy__contact--sheet gavc-text-center" @click="$emit('details')">
        <i  class="gavc-icon gavc-icon-document"></i>
        <p v-if="step === 'CANDIDATURE' && candidature.state === '1ONGOING'" class="gavc-p gavc-strong" >
          Voir et traiter cette candidature
        </p>
        <p v-else class="gavc-p gavc-strong" >
          Fiche détaillée
        </p>
      </span>
      <span v-else class="gavc-text-center">
        <p class="gavc-chapo gavc-text-rose gavc-strong" >
          Veuillez compléter vos informations avant d'accéder aux prochaines étapes
        </p>
      </span>

      <span
        v-if="step === 'CANDIDATURE' && candidature.state === '2REFUSED'"
        class="gavc-card-candidacy__contact--sheet gavc-text-center"
        @click="$emit('details')"
      >
        <i class="gavc-icon gavc-icon-cross"></i>
        <p class="gavc-p gavc-strong" >
          Candidature refusée
        </p>
      </span>
      <span
        v-if="step !== 'CANDIDATURE' && isProfileCompleted"
        class="gavc-card-candidacy__contact--sheet gavc-text-center"
        @click="$emit('go-to-step')"
      >

        <i class="gavc-icon gavc-icon-arrow-right"></i>

        <p v-if="step === 'POURPARLER'" class="gavc-p gavc-strong" >
          Accéder aux pourparlers
        </p>
        <p v-else-if="step === 'AUDIT'" class="gavc-p gavc-strong" >
          Accéder à la phase d'audit
        </p>
        <p v-else-if="step === 'CLOSING'" class="gavc-p gavc-strong" >
          Accéder à la phase de closing
        </p>
      </span>
    </div>
    <div class="gavc-card-candidacy__status gavc-text-center">
      <i v-if="step === 'CANDIDATURE'" class="gavc-icon gavc-icon-hourglass"></i>
      <i v-if="step === 'POURPARLER'" class="gavc-icon gavc-icon-bubble-status"></i>
      <i v-if="step === 'AUDIT'" class="gavc-icon gavc-icon-search-counter"></i>
      <i v-if="step === 'CLOSING'" class="gavc-icon gavc-icon-signature"></i>

      <p v-if="step === 'CANDIDATURE'" class="gavc-strong gavc-margin-top gavc-text-uppercase">
        Candidature
      </p>
      <p v-if="step === 'POURPARLER'" class="gavc-strong gavc-margin-top gavc-text-uppercase">
        Pourparlers
      </p>
      <p v-if="step === 'AUDIT'" class="gavc-strong gavc-margin-top gavc-text-uppercase">
        Audits
      </p>
      <p v-if="step === 'CLOSING'" class="gavc-strong gavc-margin-top gavc-text-uppercase">
        Closing
      </p>
    </div>
  </div>
</div>
</template>

<script>
import renderMixins from '@/mixins/renderMixins'


export default {
  name: 'candidatureItem',
  mixins: [
    renderMixins
  ],
  props: {
    candidature: {
      type: Object,
      default: function () {
        return {
          created_at: '',
          description: '',
          repreneur: {
            user: {
              first_name: '',
              last_name: ''
            },
            birthdate: '01/01/1990'
          }
        }
      }
    },
    isProfileCompleted: {
      type: Boolean,
      default: false
    },
    step: String
  },
  mounted () {
    this.$dayjs.locale('fr')
  }
}
</script>
